/* !
 * index.js v1.0.0
 * Copyright, Connecty Inc.
 */
((FUNCTIONS) => {
	const a = 'is-active';
	const v = 'is-visible';

	const mainVisSwiper = () => {
		let splide = new Splide('.top-mainVis.splide', {
			type: 'loop',
			perPage: 1,
			autoplay: true,
			interval: 5000,
			speed: 1000,
			arrows: false,
			pagination: true,
			paginationKeyboard: true,
		}).mount();
	};

	const mainVisHeight = () => {
		const setHeight = () => {
			let vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty('--vh', `${vh}px`);
		}
		setHeight();
		window.addEventListener('resize', setHeight);
	}

	const instagramSwiper = () => {
		let splide = new Splide('.top-instagram-inner.splide', {
			type: 'loop',
			perPage: 4,
			focus: 0,
			// perMove: 1,
			autoplay: true,
			interval: 5000,
			speed: 1000,
			arrows: true,
			pagination: true,
			paginationKeyboard: true,
			gap: 30,
			breakpoints: {
				1025: {
					perPage: 1,
				}
			},
		}).mount();
	};


	// =========================

	mainVisSwiper();
	mainVisHeight();
	instagramSwiper();


})(window.FUNCTIONS);
